import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import GitHubIcon from '@mui/icons-material/GitHub'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

export default function Header(props) {
    const [mobileMenu, setMobileMenu] = useState(0)

    const toggleTheme = () => {
        if (props.theme === "light") {
            props.setTheme("dark")
        } else {
            props.setTheme("light")
        }
    }

    return (
        <Container>
            <Title>VIKTOR<br/>WALSER</Title>

            <Navigation open={mobileMenu}>
                <NavItem to="/" onClick={() => setMobileMenu(false)}>HOME</NavItem>
                <NavItem to="/about" onClick={() => setMobileMenu(false)}>ABOUT</NavItem>
                <NavItem to="/projects" onClick={() => setMobileMenu(false)}>PROJECTS</NavItem>
                <NavItem to="/resources" onClick={() => setMobileMenu(false)}>RESOURCES</NavItem>
                <NavItem to="/contact" onClick={() => setMobileMenu(false)}>CONTACT</NavItem>
            </Navigation>

            <Media>
                <GitHubIcon/>
                { props.theme === "light"
                    ? <LightModeIcon onClick={() => toggleTheme()}/>
                    : <DarkModeIcon onClick={() => toggleTheme()}/>
                }
                <BurgerMenu open={mobileMenu} onClick={() => setMobileMenu(!mobileMenu)}>
                    <div></div>
                    <div></div>
                    <div></div>
                </BurgerMenu>
            </Media>
        </Container>
    )
}

const Container = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 15%;
    height: 80px;
    background: rgba(${props => props.theme.primary}, 0.7);
    backdrop-filter: blur(8px);
    z-index: 99;

    @media (max-width: 900px) {
        padding: 0 30px;
    }
`

const Title = styled.h3`
    line-height: 0.95;
    color: rgb(${props => props.theme.color});
    cursor: pointer;
`

const Navigation = styled.nav`
    display: flex;
    gap: 20px;

    @media (max-width: 900px) {
        position: absolute;
        height: 100vh;
        top: 0;
        right: 0;
        flex-direction: column;
        gap: 50px;
        padding: 6rem 8rem 6rem 4rem;
        background: rgb(${props => props.theme.secondary});
        box-shadow: 0 0 5px 2px rgb(0 0 0 / .15);
        z-index: 1;
        transform: translateX(100%);
        transition: all 250ms;

        &[open] {
            transform: translateX(0);
        }
    }
`

const NavItem = styled(Link)`
    color: rgb(${props => props.theme.color});
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: rgb(${props => props.theme.color});
        transform: scale(0);
        transition: all 250ms ease-in-out;
    }

    &:hover::after {
        transform: scale(1);
    }
`

const Media = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    & > * {
        color: rgb(${props => props.theme.color});
        opacity: 0.7;
        cursor: pointer;
        transition: all 250ms ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
`

const BurgerMenu = styled.div`
    position: relative;
    width: 25px;
    height: 18px;
    z-index: 99;

    div {
        position: absolute;
        width: 100%;
        height: 2px;
        background: rgb(${props => props.theme.color});
        transition: all 250ms;

        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2) {
            top: 8px;
        }
        &:nth-child(3) {
            top: 16px;
        }
    }

    &[open] {
        div:nth-child(1) {
            transform: translateY(8px) rotate(135deg);
        }
        div:nth-child(2) {
            transform: scale(0);
        }
        div:nth-child(3) {
            transform: translateY(-8px) rotate(-135deg);
        }
    }

    @media (min-width: 900px) {
        display: none;
    }
`