import React from 'react'
import styled from 'styled-components'

export default function Footer() {
  return (
    <Container>
      <Copyright>© 2022 Copyright Viktor Walser. All rights reserved.</Copyright>
    </Container>
  )
}

const Container = styled.footer`
  display: flex;
  align-items: center;
  position: relative;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 5%;
  background: rgb(${props => props.theme.primary});
`

const Copyright = styled.p`
  font-size: 0.9rem;
  color: rgb(${props => props.theme.color});
`