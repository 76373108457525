import React from 'react'
import styled from 'styled-components'

export default function PageNotFound() {
  return (
    <Container>
        <Wrap>
            <Title>404</Title>
            <SubTitle>Page Not Found!</SubTitle>
            <Description>We're sorry, the page you requested could not be found. Please go back to the homepage!</Description>
            <RouteButton>GO HOME</RouteButton>
        </Wrap>
    </Container>
  )
}

const Container = styled.section`
    height: 100vh;
    background: rgb(${props => props.theme.primary});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Wrap = styled.div`
    width: 400px;
`

const Title = styled.h1`
    font-size: 5rem;
    color: rgb(${props => props.theme.color});
`

const SubTitle = styled.h2`
    font-size: 2.5rem;
    color: rgb(${props => props.theme.secondaryColor});
`

const Description = styled.p`
    font-size: 1.15rem;
    color: rgb(${props => props.theme.secondaryColor});
    margin: 20px 0;
`

const RouteButton = styled.button`
    padding: .8em;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    background: var(--tertiary);
    border: none;
    border-radius: 2px;
    outline: none;
    color: white;
    cursor: pointer;

    &:active {
        transform: scale(0.95);
    }
`