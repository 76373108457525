import React from 'react'
import styled from 'styled-components'
import JavascriptImage from '../../assets/images/js.png'
import TypescriptImage from '../../assets/images/ts.png'
import VueImage from '../../assets/images/vue.png'
import ReactImage from '../../assets/images/react.png'
import CSharpImage from '../../assets/images/c-sharp.png'
import LuaImage from '../../assets/images/lua.png'
import MySqlImage from '../../assets/images/mysql.png'
import MongoDBImage from '../../assets/images/mongodb.png'

export default function TechStack() {
  return (
    <Container>
        <Title>Lorem ipsum dolor sit amet.</Title>
        <SubTitle>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et pariatur est natus corrupti itaque sequi!</SubTitle>
        <Grid>
          <GridItem>
            <GridItemImage src={JavascriptImage}/>
            <GridItemTitle>JAVASCRIPT</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemImage src={TypescriptImage}/>
            <GridItemTitle>TYPESCRIPT</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemImage src={VueImage}/>
            <GridItemTitle>VUE</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemImage src={ReactImage}/>
            <GridItemTitle>REACT</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemImage src={CSharpImage}/>
            <GridItemTitle>C-SHARP</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemImage src={LuaImage}/>
            <GridItemTitle>LUA</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemImage src={MySqlImage}/>
            <GridItemTitle>MYSQL</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
          <GridItem>
            <GridItemImage src={MongoDBImage}/>
            <GridItemTitle>MONGODB</GridItemTitle>
            <GridItemContent>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, quibusdam.</GridItemContent>
          </GridItem>
        </Grid>
    </Container>
  )
}

const Container = styled.section`
  background: rgb(${props => props.theme.secondary});
  padding: 100px 15%;

  @media (max-width: 900px) {
    padding: 50px;
  }
`

const Title = styled.h1`
  color: rgb(${props => props.theme.color});
  font-size: 2.5rem;
  text-align: center;
`

const SubTitle = styled.h3`
  color: rgb(${props => props.theme.secondaryColor});
  text-align: center;
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 50px;
  margin-top: 50px;
`

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 1 / 1.25;
`

const GridItemImage = styled.img`
  height: 80px;
  margin-bottom: 10px;
`

const GridItemTitle = styled.h2`
  color: rgb(${props => props.theme.color});
`

const GridItemContent = styled.p`
  color: rgb(${props => props.theme.secondaryColor});
  text-align: center;
  font-size: 0.85rem;
`