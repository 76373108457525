import React from 'react'
import styled from 'styled-components'

export default function ContactForm() {
  return (
    <Container>
        <Content>
          <Title>Let's get started</Title>
          <Info>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minus, consequuntur. Rem aspernatur cumque tempore odio aut officiis quisquam minima laborum?</Info>
        </Content>
        <Form>
          <Label htmlFor="name">Name</Label>
          <Input id="name" type="text" required/>

          <Label htmlFor="email">Email</Label>
          <Input id="email" type="email" required/>

          <Label htmlFor="message">Message</Label>
          <Textarea id="message" cols="40" rows="5"></Textarea>

          <SubmitButton>LET'S GET STARTED</SubmitButton>
        </Form>
    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 25px;
  background: rgb(${props => props.theme.secondary});
  padding: 50px 15%;

  @media (max-width: 900px) {
    padding: 50px;
  }
`

const Content = styled.div``

const Title = styled.h1`
  font-size: 2.5rem;
  letter-spacing: 3px;
  color: rgb(${props => props.theme.color});
`

const Info = styled.p`
  color: rgb(${props => props.theme.secondaryColor});
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  color: rgb(${props => props.theme.secondaryColor});
`

const Input = styled.input`
  height: 35px;
  margin-bottom: 25px;
  outline: none;
  border: none;
  background: rgb(${props => props.theme.primary});
  padding-left: 10px;
  color: rgb(${props => props.theme.color});

  &::placeholder {
    color: rgb(${props => props.theme.secondaryColor});
  }
`

const Textarea = styled.textarea`
  margin-bottom: 25px;
  outline: none;
  border: none;
  resize: vertical;
  max-height: 300px;
  min-height: 100px;
  color: rgb(${props => props.theme.color});
  padding: 10px;
  background: rgb(${props => props.theme.primary});
`

const SubmitButton = styled.button`
  padding: 0.8em;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  background: var(--tertiary);
  outline: none;
  border: none;
  border-radius: 2px;
  color: white;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }
`