import React from 'react'
import styled from 'styled-components'

export default function About() {
  return (
    <Container>
        <Title>Lorem ipsum dolor sit amet.</Title>
        <SubTitle>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et pariatur est natus corrupti itaque sequi!</SubTitle>
    </Container>
  )
}

const Container = styled.section`
  height: 100vh;
  background: rgb(${props => props.theme.primary});
  padding: 100px 15%;
`

const Title = styled.h1`
  color: rgb(${props => props.theme.color});
  font-size: 2.5rem;
  text-align: center;
`

const SubTitle = styled.h3`
  color: rgb(${props => props.theme.secondaryColor});
  text-align: center;
`