import './App.css';
import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import Header from './components/base/Header'
import Footer from './components/base/Footer'
import Home from './pages/Home'
import PageNotFound from './pages/PageNotFound';

const LightTheme = {
  primary: "255, 255, 255",
  secondary: "240, 240, 240",
  color: "0, 0, 0", // rename to primaryColor
  secondaryColor: "51, 64, 64",
  scrollThumb: "100, 100, 100",
  scrollTrack: "215, 215, 215"
}

const DarkTheme = {
  primary: "0, 30, 38",
  secondary: "1, 42, 53",
  color: "255, 255, 255",
  secondaryColor: "177, 203, 203",
  scrollThumb: "75, 75, 75",
  scrollTrack: "0, 16, 21"
}

const themes = {
  light: LightTheme,
  dark: DarkTheme
}

export default function App() {
  const [theme, setTheme] = useState("dark")

  return (
    <div className="App">
      <ThemeProvider theme={themes[theme]}>
        <Header theme={theme} setTheme={setTheme}/>
        <Routes>
          <Route path="*" element={<PageNotFound/>}/>
          <Route path="/" element={<Home/>}/>
        </Routes>
        <Footer/>
        <GlobalStyle/>
      </ThemeProvider>
    </div>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    background: rgb(${props => props.theme.primary});
  }

  body::-webkit-scrollbar {
    width: 7px;
  }

  body::-webkit-scrollbar-thumb {
    background: rgba(${props => props.theme.scrollThumb}, 0.45);
    border-radius: 5px;
    width: 5px;
  }

  body::-webkit-scrollbar-track {
    background: rgb(${props => props.theme.scrollTrack});
  }
`