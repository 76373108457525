import React from 'react'
import Main from '../components/home/Main'
import TechStack from '../components/home/TechStack'
import About from '../components/home/About'
import ContactForm from '../components/home/ContactForm'

export default function Home() {
  return (
    <>
        <Main/>
        <TechStack/>
        <About/>
        <ContactForm/>
    </>
  )
}