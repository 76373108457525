import React from 'react'
import styled from 'styled-components'

export default function Main() {
    return (
        <Container>
            <Title>I'M VIKTOR WALSER,<br/> A PROGRAMMER</Title>
            <Subtitle>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae dolorum provident corrupti vero odio temporibus, adipisci quas labore dolore magni ratione pariatur neque ab commodi dolorem nobis atque dolores. Fugiat.</Subtitle>
        </Container>
    )
}

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 30px;
    height: 100vh;
    background: rgb(${props => props.theme.primary});
    padding: 0 20%;

    @media (max-width: 900px) {
        padding: 0 50px;
    }
`

const Title = styled.h1`
    font-size: clamp(2rem, 3.5vw, 3.5rem);
    color: rgb(${props => props.theme.color});
`

const Subtitle = styled.h3`
    font-size: clamp(0.9rem, 1.5vw, 1.25rem);
    color: rgb(${props => props.theme.secondaryColor});
`